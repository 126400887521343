import styled from 'styled-components';

interface IFloatingButtonProps {
  isOpen: boolean;
}

export const Container = styled.div`
  div.whatsapp-button {
    position: fixed;
    bottom: 10px;
    right: 20px;
    width: 65px;
    z-index: 999;

    cursor: pointer;
  }

  img {
    width: 100%;
  }
`;

export const FloatingMenu = styled.div`
  position: absolute;
  top: -100px;
  right: 0px;
  z-index: 9999;

  width: 170px;
`;

export const FloatingButton = styled.div<IFloatingButtonProps>`
  padding: 8px 16px;

  margin-bottom: 12px;

  background-color: ${props => props.theme.colors.primary};
  color: white;

  opacity: ${props => (props.isOpen ? 1 : 0)};
  display: ${props => (props.isOpen ? 'block' : 'none')};

  transition: opacity 0.3s;

  border-radius: 4px;

  &:hover {
    background-color: #68ccc2;

    transition: background-color 0.3s;
  }
`;
