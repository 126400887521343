import React from 'react';

const UDC: React.FC = () => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4559 5.09861L11.2137 0.0673819C11.031 -0.0224606 10.819 -0.0224606 10.6363 0.0673819L0.394084 5.09861C0.153349 5.21657 0 5.47063 0 5.75001V12.9375H1.3656V6.87857L2.73121 7.54941V14.375C2.73121 14.6993 2.93724 14.9828 3.23463 15.0685L10.7456 17.2247C10.8043 17.2416 10.8643 17.25 10.925 17.25C10.9857 17.25 11.0457 17.2416 11.1044 17.2247L18.6153 15.0685C18.9127 14.9829 19.1187 14.6993 19.1187 14.375V7.54941L21.4559 6.40135C21.6967 6.28345 21.85 6.02933 21.85 5.75001C21.85 5.47063 21.6967 5.21657 21.4559 5.09861ZM17.7531 13.8261L10.925 15.7858L4.09686 13.8261V8.22029L10.6363 11.4326C10.7276 11.4775 10.8263 11.5 10.925 11.5C11.0237 11.5 11.1223 11.4776 11.2137 11.4326L17.7531 8.22029C17.7531 8.22024 17.7531 13.8261 17.7531 13.8261ZM10.925 9.98811L2.29714 5.75001L10.925 1.5119L19.5528 5.75001L10.925 9.98811Z"
        fill="white"
      />
    </svg>
  );
};

export default UDC;
