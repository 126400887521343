import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  background: #ffffff;
  border-radius: 5px;

  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.16);

  max-width: 380px;

  span.capitalize {
    text-transform: capitalize;
  }
`;

export const HelperHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 16px;
  font-weight: 500;

  svg {
    cursor: pointer;
  }
`;

export const HelperContent = styled.div`
  margin-bottom: 24px;
  color: ${props => props.theme.colors.titleTextColor};

  font-size: 12px;
  font-weight: normal;
`;

export const HelperFooter = styled.div``;

export const HelperNavigation = styled.div``;
