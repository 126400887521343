import React, { useEffect } from 'react';

import Button from 'antd/es/button';
import Result from 'antd/es/result';
import { useAuth } from 'src/hooks/auth';
import { Link, useHistory } from 'react-router-dom';
import AuthLayout from '../_layouts/auth';

const NotFound: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/login');
    }
  }, [isAuthenticated, history]);

  return (
    <AuthLayout>
      <Result
        style={{ padding: '16px 16px 0 16px' }}
        status="404"
        title="Não encontramos essa página."
        subTitle="Mas não se preocupe, está tudo sob o controle e a transparência de costume da Combudata!"
        extra={
          <Link to="/dashboard">
            <Button type="primary">Voltar para a Página Inicial</Button>
          </Link>
        }
      />
    </AuthLayout>
  );
};

export default NotFound;
