import React, { Suspense } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import mixpanel from 'mixpanel-browser';

import Skeleton from 'antd/es/skeleton';

import { useAuth } from 'src/hooks/auth';
import PublicLayout from 'src/pages/_layouts/public';

interface IRouteProps extends RouteProps {
  routeName: string;
}

const PublicRoute: React.FC<IRouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (String(process.env.REACT_APP_ENV) === 'production') {
    mixpanel.track(`Página ${rest.routeName}`);
  }

  return (
    <PublicLayout>
      <Suspense fallback={<Skeleton active />}>
        <Route
          {...rest}
          render={props => {
            return !isAuthenticated ? (
              children
            ) : (
              <Redirect
                to={{ pathname: '/dashboard', state: props.location }}
              />
            );
          }}
        />
      </Suspense>
    </PublicLayout>
  );
};

export default PublicRoute;
