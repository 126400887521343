import React, { createContext, useCallback, useContext, useState } from 'react';

import axios from 'axios';
import api from 'src/services/api';

import { TablePaginationConfig } from 'antd/es/table';
import notification from 'antd/es/notification';

import {
  IQuotationContext,
  IQuotationData,
  IQuotationHistory,
  QuotationHistoryFilter,
} from 'src/types/Quotation';

const QuotationContext = createContext<IQuotationContext>(
  {} as IQuotationContext,
);

const QuotationProvider: React.FC = ({ children }) => {
  const [quotations, setQuotations] = useState<IQuotationData[]>([]);
  const [quotationHistory, setQuotationHistory] = useState<IQuotationHistory[]>(
    [],
  );
  const [paginationHistory, setPaginationHistory] =
    useState<TablePaginationConfig>({
      pageSize: 10,
      current: 1,
      hideOnSinglePage: true,
    });

  const addNewQuotation = (newItem: IQuotationData) => {
    const newQuotations = [...quotations];

    newQuotations.unshift(newItem);

    setQuotations(newQuotations);
  };

  const removeQuotation = (index: number) => {
    const newQuotations = [...quotations];

    newQuotations.splice(index, 1);

    setQuotations(newQuotations);
  };

  const emptyArray = () => {
    setQuotations([]);
  };

  const updateQuotationType = (index: number, type: 1 | 2) => {
    const newQuotations = [...quotations];
    const quotationToUpdate = { ...quotations[index] };

    quotationToUpdate.tipoCotacao = type;
    newQuotations[index] = quotationToUpdate;

    setQuotations(newQuotations);
  };

  const updateQuotationHistory = useCallback(
    async (
      page = 1,
      params?: QuotationHistoryFilter,
      sorter = '',
    ): Promise<void> => {
      try {
        const response = await api.get(`/api/cotacoes/`, {
          params: {
            page,
            pageSize: 10,
            ordering: sorter,
            ...params,
          },
        });

        setPaginationHistory({
          pageSize: 10,
          current: page,
          total: response.data.count,
          hideOnSinglePage: true,
        });

        setQuotationHistory(response.data.results);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          notification.error({
            message: 'Erro ao buscar cotações.',
            description:
              'Verifique os filtros e tente novamente, ou entre em contato com nosso suporte.',
          });
        }

        setQuotationHistory([]);
      }
    },
    [],
  );

  return (
    <QuotationContext.Provider
      value={{
        quotations,
        quotationHistory,
        paginationHistory,
        updateQuotationHistory,
        addNewQuotation,
        removeQuotation,
        emptyArray,
        updateQuotationType,
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

const useQuotes = (): IQuotationContext => {
  const context = useContext(QuotationContext);

  return context;
};

export { QuotationProvider, useQuotes };
