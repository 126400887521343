import React, { useMemo } from 'react';

import { useMenu } from 'src/hooks/menu';

import WhatsAppButton from 'src/components/WhatsAppButton';

import MainHeader from './MainHeader';
import RDM from './RDM';
import Sibebar from './Sidebar';
import Content from './Content';

import { Layout } from './styles';

const AuthLayout: React.FC = ({ children }) => {
  const { openMenu } = useMenu();

  const gridTemplateColumns = useMemo(() => {
    return openMenu ? '275px auto' : '73px auto';
  }, [openMenu]);

  return (
    <Layout style={{ gridTemplateColumns }}>
      <MainHeader />
      <RDM />
      <Sibebar />
      <Content>{children}</Content>
      <WhatsAppButton />
    </Layout>
  );
};

export default AuthLayout;
