const theme = {
  colors: {
    success: '#16E469',
    warning: '#F2F10C',
    attention: '#FF7A00',
    error: '#F21C47',
    primary: '#42BFB7',
    titleTextColor: '#4f4f4f',
    bodyTextColor: '#8A8A8A',
  },
};

export default theme;
