import React from 'react';

// import { Container } from './styles';

const ArrowIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7" r="7" transform="rotate(-90 7 7)" fill="#262E34" />
      <path
        d="M9.83164 5.18621C9.725 5.06694 9.58074 5 9.43037 5C9.28 5 9.13575 5.06694 9.0291 5.18621L6.98577 7.45305L4.9709 5.18621C4.86425 5.06694 4.72 5 4.56963 5C4.41926 5 4.275 5.06694 4.16836 5.18621C4.11501 5.24574 4.07267 5.31656 4.04377 5.39459C4.01488 5.47263 4 5.55632 4 5.64086C4 5.72539 4.01488 5.80909 4.04377 5.88712C4.07267 5.96515 4.11501 6.03598 4.16836 6.0955L6.58166 8.81059C6.63457 8.8706 6.69752 8.91824 6.76688 8.95075C6.83624 8.98326 6.91063 9 6.98577 9C7.06091 9 7.1353 8.98326 7.20466 8.95075C7.27402 8.91824 7.33697 8.8706 7.38988 8.81059L9.83164 6.0955C9.88499 6.03598 9.92733 5.96515 9.95623 5.88712C9.98512 5.80909 10 5.72539 10 5.64086C10 5.55632 9.98512 5.47263 9.95623 5.39459C9.92733 5.31656 9.88499 5.24574 9.83164 5.18621Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowIcon;
