import React, { useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import notification from 'antd/es/notification';

import { useFilter } from 'src/hooks/filter';
import { useAuth } from 'src/hooks/auth';
import { useConfig } from 'src/hooks/config';
import useOnClickOutside from 'src/hooks/outsideClick';

import { fuelInstanceTypes } from 'src/utils/dictionaries';
import { arrayContainsSome } from 'src/utils/helper';

import Logo from 'src/assets/logos/logo-mono.svg';
import ArrowIcon from '../ArrowIcon';

import {
  Container,
  SelectedFuel,
  FuelPanelOptions,
  FuelOption,
} from './styles';

const SelectFuelInstance: React.FC = () => {
  const panelOptionsRef = useRef(null);
  const [showPanelOptions, setShowPanelOptions] = useState(false);

  const { fuelInstance, updateFuelInstance, canBuyFuels } = useConfig();
  const { filterValues, updateFilterValues } = useFilter();
  const { updateCompanyListByFuel } = useAuth();

  useOnClickOutside(panelOptionsRef, () => setShowPanelOptions(false));

  const handleShowPanelOptions = (): void => {
    setShowPanelOptions(prevState => !prevState);
  };

  const handleSelectFuelInstance = (option: 1 | 2 | 3): void => {
    const newFilterValues = { ...filterValues };
    const fuelTypesArray = option === 1 ? [1, 2] : option === 2 ? [3] : [5];

    newFilterValues.tipoCombustivel = [String(option)];

    newFilterValues.hasCompanyForFuelInstance = arrayContainsSome(
      fuelTypesArray,
      canBuyFuels,
    );

    if (!newFilterValues.hasCompanyForFuelInstance) {
      const fuelInstanceLabel =
        fuelInstance === 1
          ? 'Diesel'
          : fuelInstance === 2
          ? 'Gasolina'
          : 'Etanol';

      notification.warn({
        message: 'Combustível inválido para esse usuário!',
        description: `Você não possui nenhuma empresa com tanques cadastrados para o combustível ${fuelInstanceLabel}. Para mais informações, contate nosso suporte.`,
      });
    }

    updateFuelInstance(option);
    updateCompanyListByFuel(option);
    updateFilterValues({ ...newFilterValues });

    setShowPanelOptions(false);
  };

  const CombudataLogo = useMemo(() => {
    return <img className="logo" src={Logo} alt="Combudata Logo" />;
  }, []);

  return (
    <Container>
      <Link to="/dashboard">{CombudataLogo}</Link>

      <button type="button" onClick={handleShowPanelOptions}>
        <SelectedFuel fuelInstance={fuelInstanceTypes[fuelInstance]}>
          {fuelInstanceTypes[fuelInstance]}
        </SelectedFuel>

        <ArrowIcon key="arrow-down-icon" />
      </button>

      {showPanelOptions && (
        <FuelPanelOptions ref={panelOptionsRef}>
          {fuelInstance !== 1 && (
            <FuelOption onClick={() => handleSelectFuelInstance(1)}>
              Diesel
            </FuelOption>
          )}
          {fuelInstance !== 2 && (
            <FuelOption onClick={() => handleSelectFuelInstance(2)}>
              Gasolina
            </FuelOption>
          )}
          {fuelInstance !== 3 && (
            <FuelOption onClick={() => handleSelectFuelInstance(3)}>
              Etanol
            </FuelOption>
          )}
        </FuelPanelOptions>
      )}
    </Container>
  );
};

export default SelectFuelInstance;
