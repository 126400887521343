import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Menu from 'antd/es/menu';
import Tooltip from 'antd/es/tooltip';
import Button from 'antd/es/button';

import {
  DashboardOutlined,
  LineChartOutlined,
  HistoryOutlined,
  DollarCircleOutlined,
  MenuOutlined,
  CloseOutlined,
  BarChartOutlined,
  LeftOutlined,
  RightOutlined,
  ReconciliationOutlined,
  WalletOutlined,
} from '@ant-design/icons';

import { useMenu } from 'src/hooks/menu';
import { useConfig } from 'src/hooks/config';

import ModalExtendPayment from 'src/components/ModalExtendPayment';

import { ReactComponent as DocumentIcon } from 'src/assets/icons/document.svg';
import { ReactComponent as PolicyIcon } from 'src/assets/icons/privacy.svg';

import { Container } from './styles';

const Sidebar: React.FC = () => {
  const [menuMobileIsOpened, setMenuMobileIsOpened] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const location = useLocation();
  const { openMenu, handleOpenCloseMenu } = useMenu();
  const { fuelInstance } = useConfig();

  const handleToggleMenu = () => {
    setMenuMobileIsOpened(!menuMobileIsOpened);
  };

  const handleMenu = (): void => {
    if (openMenu) {
      handleOpenCloseMenu();
      setShowTooltip(false);
    } else {
      handleOpenCloseMenu();
      setShowTooltip(false);
    }
  };

  return (
    <Container menuMobileIsOpen={menuMobileIsOpened}>
      {menuMobileIsOpened ? (
        <div className="initial-action">
          <Button
            type="primary"
            className="button-toggle-menu button-toggle-close"
            onClick={handleToggleMenu}
          >
            <CloseOutlined />
          </Button>
        </div>
      ) : (
        <Button
          type="primary"
          className="button-toggle-menu button-toggle"
          onClick={handleToggleMenu}
        >
          <MenuOutlined />
        </Button>
      )}

      <div className="div-button-open-close">
        <Tooltip
          placement="right"
          color="#42BFB7"
          title={openMenu ? 'Recolher menu lateral' : 'Abrir menu lateral'}
          trigger="hover"
          visible={showTooltip}
          onVisibleChange={setShowTooltip}
          mouseEnterDelay={0.5}
        >
          <Button type="primary" shape="circle" onClick={handleMenu}>
            {openMenu ? <LeftOutlined /> : <RightOutlined />}
          </Button>
        </Tooltip>
      </div>

      <div className="menu-content">
        <Menu
          id="aside-menu-tour"
          mode="inline"
          selectedKeys={[location.pathname.replace(/\/+$/, '')]}
        >
          <Menu.Item key="/dashboard">
            <Tooltip
              title={openMenu ? '' : 'Visão geral'}
              placement="right"
              color="#42BFB7"
              mouseEnterDelay={0.5}
            >
              <Link to="/dashboard">
                <DashboardOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Visão geral
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>

          <Menu.Item key="/historico">
            <Tooltip
              title={openMenu ? '' : 'Histórico'}
              placement="right"
              color="#42BFB7"
              mouseEnterDelay={0.5}
            >
              <Link to="/historico">
                <HistoryOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Histórico
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>

          <Menu.Item key="/mais-prazo">
            <Tooltip
              title={openMenu ? '' : '+ Prazo'}
              placement="right"
              color="#42BFB7"
            >
              <Link to="/mais-prazo">
                <WalletOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  + Prazo
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>

          <Menu.Item key="/margens">
            <Tooltip
              title={openMenu ? '' : 'Margens'}
              placement="right"
              color="#42BFB7"
              mouseEnterDelay={0.5}
            >
              <Link to="/margens">
                <BarChartOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Margens
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>

          {fuelInstance === 1 && (
            <>
              <Menu.Item key="/reajustes">
                <Tooltip
                  title={openMenu ? '' : 'Reajustes'}
                  placement="right"
                  color="#42BFB7"
                  mouseEnterDelay={0.5}
                >
                  <Link to="/reajustes">
                    <LineChartOutlined className="sibebar-icon" />
                    <span
                      className={openMenu ? 'link-name' : 'link-name-close'}
                    >
                      Reajustes
                    </span>
                  </Link>
                </Tooltip>
              </Menu.Item>

              {/*
               <Menu.Item key="/comparacao-anp">
                <Tooltip
                  title={openMenu ? '' : 'Comparação ANP'}
                  placement="right"
                  color="#42BFB7"
                >
                  <Link to="/comparacao-anp">
                    <LineChartOutlined className="sibebar-icon" />
                    <span
                      className={
                        openMenu ? 'link-name' : 'link-name-close'
                      }
                    >
                      Comparação ANP
                    </span>
                  </Link>
                </Tooltip>
              </Menu.Item> */}
            </>
          )}

          <Menu.Item key="/bonificacoes">
            <Tooltip
              title={openMenu ? '' : 'Bonificações'}
              placement="right"
              color="#42BFB7"
              mouseEnterDelay={0.5}
            >
              <Link to="/bonificacoes">
                <DollarCircleOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Bonificações
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>
          {/*
          <Menu.Item key="/cotacoes">
            <Tooltip
              title={openMenu ? '' : 'Cotações'}
              placement="right"
              color="#42BFB7"
            >
              <Link to="/cotacoes">
                <FileDoneOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Cotações
                </span>
              </Link>
            </Tooltip>
          </Menu.Item> */}

          <Menu.Item key="/estoque">
            <Tooltip
              title={openMenu ? '' : 'Estoque'}
              placement="right"
              color="#42BFB7"
            >
              <Link to="/estoque">
                <ReconciliationOutlined className="sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Estoque
                </span>
              </Link>
            </Tooltip>
          </Menu.Item>
        </Menu>
      </div>

      <div className="menu-policy">
        <Menu
          mode="inline"
          selectedKeys={[location.pathname.replace(/\/+$/, '')]}
        >
          <Menu.Item key="politica-privacidade">
            <Tooltip
              title={openMenu ? '' : 'Política de privacidade'}
              placement="right"
              color="#42BFB7"
            >
              <a
                href="https://www.combudata.com/politica-de-privacidade/"
                className="no-hover"
                target="_blank"
                rel="noreferrer"
              >
                <PolicyIcon className="anticon sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Política de privacidade
                </span>
              </a>
            </Tooltip>
          </Menu.Item>
          <Menu.Item key="condicoes-gerais">
            <Tooltip
              title={openMenu ? '' : 'Condições gerais'}
              placement="right"
              color="#42BFB7"
            >
              <a
                href="https://www.combudata.com/condicoes-gerais/"
                target="_blank"
                rel="noreferrer"
                className="no-hover"
              >
                <DocumentIcon className="anticon sibebar-icon" />
                <span className={openMenu ? 'link-name' : 'link-name-close'}>
                  Condições gerais
                </span>
              </a>
            </Tooltip>
          </Menu.Item>
        </Menu>
      </div>

      <ModalExtendPayment />
    </Container>
  );
};

export default Sidebar;
