import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { privateRoutesList, publicRoutesList } from 'src/routes/routes';
import NotFound from 'src/pages/NotFound';

import PublicRoute from './PublicRoutes';
import PrivateRoute from './PrivateRoutes';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {publicRoutesList.map(({ Component, path, name }) => (
          <PublicRoute key={name} routeName={name} path={path} exact>
            <Component />
          </PublicRoute>
        ))}

        {privateRoutesList.map(({ Component, path, name, fuelHasAccess }) => (
          <PrivateRoute
            key={name}
            routeName={name}
            path={path}
            fuelHasAccess={fuelHasAccess}
            exact
          >
            <Component />
          </PrivateRoute>
        ))}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
