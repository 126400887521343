import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useReducer,
} from 'react';

import mixpanel from 'mixpanel-browser';

import api from 'src/services/api';

import notification from 'antd/es/notification';

import { authReducer } from 'src/lib/reducers/AuthReducer';

import { IUserData } from 'src/types/User';
import {
  AuthState,
  IAuthContext,
  IAuthenticationReturnData,
} from 'src/types/Auth';
import { ICompanyData } from 'src/types/Company';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

const initialState: AuthState = {
  isAuthenticated: false,
  firstLogin: false,
  mainCompany: {} as ICompanyData,
  freemiumCompanies: [],
  goldCompanies: [],
  user: {} as IUserData,
  loading: true,
};

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const {
    isAuthenticated,
    firstLogin,
    user,
    mainCompany,
    isFreemium,
    freemiumCompanies,
    goldCompanies,
    loading,
  } = state;

  /**
   * Função que atualiza o context como sendo Freemium ou não
   * @param freemium Flag para definir se é ou não freemium
   */
  const updateIsFreemium = useCallback((freemium: boolean): void => {
    dispatch({
      type: 'UPDATE_FREEMIUM_STATE',
      payload: { isFreemium: freemium },
    });
  }, []);

  /**
   * Realiza a autenticação do usuário, verificando se ele está logado e inicializa com suas
   * informações.
   * @returns {object} Um objeto informando se deve redirecionar as configurações e se está autenticado.
   */
  const verifyAuthentication =
    useCallback(async (): Promise<IAuthenticationReturnData> => {
      try {
        dispatch({ type: 'LOADING' });

        const { data } = await api.get<IUserData>('api/usuarios/info/');

        const isFreemiumPlan = data.filiacaoPrincipal?.empresa.plano === 0;
        updateIsFreemium(isFreemiumPlan);

        const redirectToSettings =
          data.filiacoes?.length === 1 &&
          data.filiacoes[0].empresa.tiposCombustivel.length === 0;

        dispatch({ type: 'AUTHENTICATE', payload: { data } });

        if (String(process.env.REACT_APP_ENV) === 'production') {
          const hasGoldCompanies =
            data.filiacoes.filter(item => item.empresa.plano > 0).length > 0;

          mixpanel.identify(data.id?.toString());
          mixpanel.people.set({
            $name: `${data.firstName} ${data.lastName}`,
            $first_name: data.firstName,
            $last_name: data.lastName,
            $email: data.email,
            empresa: data.filiacaoPrincipal?.empresa.nomeFantasia,
            Freemium: !hasGoldCompanies,
          });

          mixpanel.track('Autenticação', {
            $name: `${data.firstName} ${data.lastName}`,
            $first_name: data.firstName,
            $last_name: data.lastName,
            $email: data.email,
            empresa: data.filiacaoPrincipal?.empresa.nomeFantasia,
          });
        }

        return {
          isAuthenticated: true,
          redirectToSettings,
          user: data,
        };
      } catch (e) {
        dispatch({ type: 'AUTHENTICATE_FAIL' });

        return { isAuthenticated: false, redirectToSettings: false };
      }
    }, [updateIsFreemium]);

  const updateCompanyListByFuel = useCallback((fuelInstance: 1 | 2 | 3) => {
    dispatch({
      type: 'UPDATE_COMPANY_LIST_BY_FUEL',
      payload: {
        fuelInstance,
      },
    });
  }, []);

  const updateUserInfo = async () => {
    try {
      const { data } = await api.get<IUserData>('api/usuarios/info/');

      dispatch({ type: 'UPDATE_USER_INFO', payload: { data } });
    } catch (e) {
      notification.error({
        message: 'Erro ao atualizar informações do usuário',
      });
    }
  };

  /**
   * Realiza o logout da aplicação apenas enviando um post para o endpoint de logout. Caso tenha
   * sucesso na requisição, é feito um reload da página para redirecionar a tela de login.
   */
  const logout = useCallback(async (): Promise<void> => {
    try {
      await api.get('api/logout/');

      window.location.reload();
    } catch (e) {
      notification.error({
        message: 'Não foi possível realizar o logout da aplicação!',
        description: 'Atualize a página e tente novamente',
      });
    }
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      verifyAuthentication();
    }
  }, [verifyAuthentication, isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        updateUserInfo,
        isAuthenticated,
        verifyAuthentication,
        logout,
        user,
        mainCompany,
        firstLogin,
        isFreemium,
        updateIsFreemium,
        updateCompanyListByFuel,
        freemiumCompanies,
        goldCompanies,
      }}
    >
      {loading ? 'Carregando' : children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
