import { ConfigAction, ConfigState, FuelInstanceType } from 'src/types/Config';

export function configReducer(
  state: ConfigState,
  action: ConfigAction,
): ConfigState {
  switch (action.type) {
    case 'LOADING': {
      const newState = { ...state };
      newState.loading = true;

      return newState;
    }

    case 'UPDATE_LAST_RESULT': {
      const newState = { ...state };
      newState.lastResultId = action.payload?.lastResultId;

      return newState;
    }

    case 'UPDATE_PRIVACY': {
      const newState = { ...state };
      newState.acceptPrivacyPolicy = !!action.payload?.acceptPrivacyPolicy;

      if (action.payload?.acceptPrivacyPolicy) {
        localStorage.setItem('privacyPolicy', 'true');
      }

      return newState;
    }

    case 'UPDATE_FUEL_INSTANCE': {
      const newState = { ...state };
      newState.fuelInstance = Number(
        action.payload?.fuelInstance,
      ) as FuelInstanceType;

      localStorage.setItem(
        'fuelInstance',
        String(action.payload?.fuelInstance),
      );

      return newState;
    }

    case 'UPDATE_CAN_BUY_FUELS': {
      const newState = { ...state };

      newState.canBuyFuels = action.payload?.canBuyFuels as number[];

      return newState;
    }

    case 'UPDATE_HAS_BNF': {
      const newState = { ...state };
      newState.hasBnf = action.payload?.hasBnf as boolean;

      return newState;
    }

    case 'SHOW_MODAL': {
      const newState = { ...state };

      newState.showInventoryTutorial = action.payload
        ?.showInventoryTutorial as boolean;

      newState.showExtendPaymentModal = action.payload
        ?.showExtendPaymentModal as boolean;

      return newState;
    }

    default:
      return state;
  }
}
