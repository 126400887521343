import React, { useMemo } from 'react';

import { format } from 'date-fns';

import api from 'src/services/api';
import axios from 'axios';
import { useQuery } from 'react-query';

import Col from 'antd/es/col';
import notification from 'antd/es/notification';
import Row from 'antd/es/row';
import Tooltip from 'antd/es/tooltip';

import { InfoCircleOutlined } from '@ant-design/icons';

import { useAuth } from 'src/hooks/auth';
import { useConfig } from 'src/hooks/config';
import {
  rdmTypes,
  rdmEtanolTypes,
  rdmTypesFreemium,
  rdmEtanolTypesFreemium,
  tendencyColor,
  tendencyColorEtanol,
} from 'src/utils/dictionaries';
import { handleFormatValues } from 'src/utils/formatters';
import {
  IRdmData,
  GenericDolarBrentRdmData,
  GenericRbobRdmData,
  DieselRdm,
  GasolinaRdm,
  EtanolRdm,
} from 'src/types/Rdm';

import {
  Container,
  RdmContent,
  RdmDivider,
  ContentValue,
  ContentVariation,
  ContentTitle,
  RoundIcon,
} from './styles';

const RDM: React.FC = () => {
  const { isFreemium } = useAuth();
  const { fuelInstance } = useConfig();

  const fetchData = async (): Promise<IRdmData> => {
    try {
      const response = await api.get('/api/rdm/last/');

      return response.data;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.status === 400) {
          notification.error({
            message: e.response.data,
          });
        }
      }

      return {} as IRdmData;
    }
  };

  const { data, isLoading, error } = useQuery<IRdmData>('rdm', fetchData, {
    refetchInterval: Number(process.env.REACT_APP_REFETCH_INTERVAL),
    staleTime: Number(process.env.REACT_APP_STALE_TIME),
  });

  const rdmByFuelData: DieselRdm | GasolinaRdm | EtanolRdm | null =
    useMemo(() => {
      switch (fuelInstance) {
        case 1: {
          return data?.diesel || null;
        }

        case 2: {
          return data?.gasolina || null;
        }

        case 3: {
          return data?.etanol || null;
        }

        default:
          return null;
      }
    }, [data, fuelInstance]);

  const renderDolarValues = useMemo(() => {
    const dolarRdm = rdmByFuelData as GenericDolarBrentRdmData;

    return (
      <>
        <ContentTitle>DÓLAR</ContentTitle>
        <ContentValue>
          R$ {dolarRdm ? handleFormatValues(dolarRdm.dolar, 2, 4) : '-'}
        </ContentValue>
        <ContentVariation value={dolarRdm?.dolarVd || 0}>
          {dolarRdm ? handleFormatValues(dolarRdm.dolarVd, 2, 4) : '-'}
          %(VD)
        </ContentVariation>
        <ContentVariation value={dolarRdm?.dolarVur || 0}>
          {dolarRdm ? handleFormatValues(dolarRdm.dolarVur, 2, 4) : '-'}
          %(VUR)
        </ContentVariation>
        <Tooltip
          title="Considerando que as cotações do petróleo e derivados no mercado internacional são realizadas em “DÓLAR”, convertemos os valores para a nossa moeda “REAL”."
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [rdmByFuelData]);

  const renderBrentValues = useMemo(() => {
    const brentRdm = rdmByFuelData as GenericDolarBrentRdmData;

    return (
      <>
        <ContentTitle>BRENT</ContentTitle>
        <ContentValue>
          US${' '}
          {brentRdm?.brent ? handleFormatValues(brentRdm?.brent, 2, 4) : '-'}
        </ContentValue>
        <ContentVariation value={brentRdm?.brentVd || 0}>
          {brentRdm?.brentVd
            ? handleFormatValues(brentRdm?.brentVd, 2, 4)
            : '-'}
          %(VD)
        </ContentVariation>
        <ContentVariation value={brentRdm?.brentVur || 0}>
          {brentRdm?.brentVur
            ? handleFormatValues(brentRdm?.brentVur, 2, 4)
            : '-'}
          %(VUR)
        </ContentVariation>
        <Tooltip
          title="Principal tipo de petróleo utilizado mundialmente pelas petrolíferas na elaboração de cenários de planejamento de investimentos futuros. É usado como referência pela Petrobras e OPEP."
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [rdmByFuelData]);

  const renderHeatingOilValues = useMemo(() => {
    const heatingOilRdm = rdmByFuelData as DieselRdm;

    return (
      <>
        <ContentTitle>HEATING OIL</ContentTitle>
        <ContentValue>
          US${' '}
          {heatingOilRdm
            ? handleFormatValues(heatingOilRdm.heatingOil, 2, 4)
            : '-'}
        </ContentValue>
        <ContentVariation
          value={heatingOilRdm ? heatingOilRdm.heatingOilVd : 0}
        >
          {heatingOilRdm
            ? handleFormatValues(heatingOilRdm.heatingOilVd, 2, 4)
            : '-'}
          %(VD)
        </ContentVariation>
        <ContentVariation
          value={heatingOilRdm ? heatingOilRdm.heatingOilVur : 0}
        >
          {heatingOilRdm
            ? handleFormatValues(heatingOilRdm.heatingOilVur, 2, 4)
            : '-'}
          %(VUR)
        </ContentVariation>
        <Tooltip
          title="É um derivado do Petróleo. Para análise de preço do Óleo Diesel no mercado nacional, utilizamos as cotações internacionais do Heating Oil, cuja simetria de preços permite a definição das tendências."
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [rdmByFuelData]);

  const renderRbobValues = useMemo(() => {
    const rbobRdm = rdmByFuelData as GenericRbobRdmData;

    return (
      <>
        <ContentTitle>RBOB</ContentTitle>
        <ContentValue>
          US$ {rbobRdm ? handleFormatValues(rbobRdm.rbob, 2, 4) : '-'}
        </ContentValue>
        <ContentVariation value={rbobRdm ? rbobRdm.rbobVd : 0}>
          {rbobRdm ? handleFormatValues(rbobRdm.rbobVd, 2, 4) : '-'}
          %(VD)
        </ContentVariation>
        <ContentVariation value={rbobRdm ? rbobRdm.rbobVur : 0}>
          {rbobRdm ? handleFormatValues(rbobRdm.rbobVur, 2, 4) : '-'}
          %(VUR)
        </ContentVariation>
        {fuelInstance === 2 && (
          <Tooltip
            title="É o tipo de contrato mais comum utilizado na comercialização de gasolina. Utilizamos as cotações internacionais do RBOB, pois a sua simetria de preços permite a análise e definição das tendências."
            color="#42BFB7"
            arrowPointAtCenter
            placement="bottomRight"
          >
            <InfoCircleOutlined
              className="margin-left-xs"
              style={{ fontSize: 16 }}
            />
          </Tooltip>
        )}
        {fuelInstance === 3 && (
          <Tooltip
            title="Utilizamos as cotações internacionais do RBOB, que é um tipo de contrato de comercialização, pois a sua simetria de preços permite a análise e definição das tendências."
            color="#42BFB7"
            arrowPointAtCenter
            placement="bottomRight"
          >
            <InfoCircleOutlined
              className="margin-left-xs"
              style={{ fontSize: 16 }}
            />
          </Tooltip>
        )}
      </>
    );
  }, [rdmByFuelData, fuelInstance]);

  const renderEsalqValues = useMemo(() => {
    const esalqRdm = rdmByFuelData as EtanolRdm;

    return (
      <>
        <ContentTitle>ESALQ DIÁRIO</ContentTitle>
        <ContentValue>
          US$ {esalqRdm ? handleFormatValues(esalqRdm.esalqDiario, 2, 4) : '-'}
        </ContentValue>
        <ContentVariation value={esalqRdm ? esalqRdm.esalqDiarioVas : 0}>
          {esalqRdm ? handleFormatValues(esalqRdm.esalqDiarioVas, 2, 4) : '-'}
          %(VAS)
        </ContentVariation>
        <Tooltip
          title="Refere-se aos valores médios diários do Etanol Hidratado e/ou Anidro, coletados pelo CEPEA/ESALQ, referentes aos negócios efetivados entre as usinas produtoras e as distribuidoras."
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [rdmByFuelData]);

  const renderMercadoFuturosValues = useMemo(() => {
    const mercadoFuturosRdm = rdmByFuelData as EtanolRdm;

    return (
      <>
        <ContentTitle>ETANOL MERCADO DE FUTUROS</ContentTitle>
        <ContentValue>
          US${' '}
          {mercadoFuturosRdm
            ? handleFormatValues(mercadoFuturosRdm.mercadoFuturos, 2, 4)
            : '-'}
        </ContentValue>
        <ContentVariation
          value={mercadoFuturosRdm ? mercadoFuturosRdm.mercadoFuturosVas : 0}
        >
          {mercadoFuturosRdm
            ? handleFormatValues(mercadoFuturosRdm.mercadoFuturosVas, 2, 4)
            : '-'}
          %(VAS)
        </ContentVariation>
        <Tooltip
          title="Para análise das tendências de preço do Etanol, utilizamos as cotações diárias do Etanol (ETHG) no mercado de futuros da BM&FBovespa, que negocia contratos de compra e venda de commodities."
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [rdmByFuelData]);

  const renderTendenciaValues = useMemo(() => {
    const tendenciaRdm = rdmByFuelData as GenericDolarBrentRdmData;
    const tendenciaCor = tendenciaRdm
      ? tendencyColor[tendenciaRdm.tendencia]
      : '#ffffff';

    return (
      <>
        <RoundIcon color={tendenciaCor} />
        Tendência:
        {tendenciaRdm ? (
          <strong className="margin-left-xxs">
            {isFreemium
              ? ` ${rdmTypesFreemium[tendenciaRdm.tendencia]}`
              : ` ${rdmTypes[tendenciaRdm.tendencia]}`}
          </strong>
        ) : (
          '-'
        )}
        <Tooltip
          title={`Atualizado em: ${
            data?.createdDate
              ? format(new Date(data.createdDate), 'd/MM/yyyy')
              : ''
          }`}
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [data, isFreemium, rdmByFuelData]);

  const renderTendenciaEtanolValues = useMemo(() => {
    const tendenciaEtanolRdm = rdmByFuelData as EtanolRdm;
    const tendenciaEtanolCor = tendenciaEtanolRdm
      ? tendencyColorEtanol[tendenciaEtanolRdm.tendencia]
      : '#ffffff';

    return (
      <>
        <RoundIcon color={tendenciaEtanolCor} />
        Tendência:
        {tendenciaEtanolRdm ? (
          <strong className="margin-left-xxs">
            {isFreemium
              ? ` ${rdmEtanolTypesFreemium[tendenciaEtanolRdm.tendencia]}`
              : ` ${rdmEtanolTypes[tendenciaEtanolRdm.tendencia]}`}
          </strong>
        ) : (
          '-'
        )}
        <Tooltip
          title={`Atualizado em: ${
            data?.createdDate
              ? format(new Date(data.createdDate), 'd/MM/yyyy')
              : ''
          }`}
          color="#42BFB7"
          arrowPointAtCenter
          placement="bottomRight"
        >
          <InfoCircleOutlined
            className="margin-left-xs"
            style={{ fontSize: 16 }}
          />
        </Tooltip>
      </>
    );
  }, [data, isFreemium, rdmByFuelData]);

  return (
    <Container id="rdm-tour">
      {isLoading ? (
        <Row justify="center" align="middle">
          Atualizando RDM...
        </Row>
      ) : error ? (
        <Row justify="center" align="middle">
          Erro ao carregar RDM. Atualize a página.
        </Row>
      ) : (
        <Row
          className="rdm-row"
          justify="space-around"
          align="middle"
          gutter={[16, 8]}
        >
          <Col>
            <RdmContent>
              {fuelInstance === 1 && renderDolarValues}

              {fuelInstance === 2 && renderDolarValues}

              {fuelInstance === 3 && renderRbobValues}
            </RdmContent>
          </Col>

          <RdmDivider />

          <Col>
            <RdmContent>
              {fuelInstance === 1 && renderBrentValues}

              {fuelInstance === 2 && renderBrentValues}

              {fuelInstance === 3 && renderEsalqValues}
            </RdmContent>
          </Col>

          <RdmDivider />

          <Col>
            <RdmContent>
              {fuelInstance === 1 && renderHeatingOilValues}

              {fuelInstance === 2 && renderRbobValues}

              {fuelInstance === 3 && renderMercadoFuturosValues}
            </RdmContent>
          </Col>

          <RdmDivider />

          <Col>
            <RdmContent>
              {fuelInstance === 1 && renderTendenciaValues}

              {fuelInstance === 2 && renderTendenciaValues}

              {fuelInstance === 3 && renderTendenciaEtanolValues}
            </RdmContent>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default RDM;
