import styled from 'styled-components';

import background from 'src/assets/background-login.png';

export const Layout = styled.div`
  text-align: center;

  background: url(${background}) no-repeat top center;
  background-size: cover;

  .ant-row-middle {
    height: 100%;
  }

  .ant-card {
    margin: 11px 0;
    max-width: 500px;
  }

  .ant-card-body {
    padding: 24px !important;
  }
`;
