import React, { Suspense } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import mixpanel from 'mixpanel-browser';

import Skeleton from 'antd/es/skeleton';

import { useAuth } from 'src/hooks/auth';
import { useConfig } from 'src/hooks/config';
import AuthLayout from 'src/pages/_layouts/auth';
import ErrorBoundary from 'src/components/ErrorBoundary';
import { FuelInstanceType } from 'src/types/Config';

interface IRouteProps extends RouteProps {
  routeName: string;
  fuelHasAccess?: FuelInstanceType[];
}

const PrivateRoute: React.FC<IRouteProps> = ({
  routeName,
  fuelHasAccess,
  children,
  ...rest
}) => {
  const { fuelInstance } = useConfig();
  const { user, isAuthenticated, firstLogin } = useAuth();

  const renderRoute = ({ location }: RouteComponentProps) => {
    if (String(process.env.REACT_APP_ENV) === 'production') {
      mixpanel.identify(user.id?.toString());
      mixpanel.track(routeName, {
        Usuario: `${user.firstName} ${user.lastName}`,
        Empresa: user.filiacaoPrincipal?.empresa.nomeFantasia || '',
      });
    }

    // caso não esteja autenticado, redireciona qualquer rota para o Login
    if (!isAuthenticated) {
      return <Redirect to={{ pathname: '/login', state: location }} />;
    }

    // caso o usuário esteja acessando pela primeira vez, ou seja, não tenha nenhuma empresa cadastrada
    // redireciona para as configurações de empresa caso não esteja nessa página
    if (firstLogin && location?.pathname !== '/configuracoes-empresa') {
      return (
        <Redirect
          to={{
            pathname: '/configuracoes-empresa',
            state: location,
          }}
        />
      );
    }

    // redireciona para a dashboard caso a página atual seja '/' ou se o Combustível acessado
    // não tenha acesso a página atual
    if (!fuelHasAccess?.includes(fuelInstance) || location?.pathname === '/') {
      return (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: location,
          }}
        />
      );
    }

    return children;
  };

  return (
    <AuthLayout>
      <Suspense fallback={<Skeleton active />}>
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
          <Route {...rest} render={renderRoute} />
        </Sentry.ErrorBoundary>
      </Suspense>
    </AuthLayout>
  );
};

export default PrivateRoute;
