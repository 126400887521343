import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import Avatar from 'antd/es/avatar';
import Tooltip from 'antd/es/tooltip';
import Menu from 'antd/es/menu';
import Image from 'antd/es/image';

import {
  PlusCircleOutlined,
  ReadOutlined,
  EnvironmentOutlined,
  SettingOutlined,
  LogoutOutlined,
  TagOutlined,
  TrophyOutlined,
} from '@ant-design/icons';

import { useAuth } from 'src/hooks/auth';

import TourDashboard from 'src/components/Onboarding/TourDashboard';
import SelectFuelInstance from 'src/components/SelectFuelInstance';

import UDC from 'src/components/HoodUDC';

import { Container, DividerHorizontal } from './styles';

const { SubMenu } = Menu;

const MainHeader: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const { user, logout } = useAuth();

  const [openTour, setOpenTour] = useState(false);

  const handleOpenTour = (): void => {
    if (location.pathname !== '/dashboard') {
      history.push('/dashboard', { openTour: true });
    } else {
      setOpenTour(true);
    }
  };

  return (
    <Container>
      <Row align="middle" justify="space-between" className="buttons-row">
        <Col>
          <SelectFuelInstance />
        </Col>

        <Col>
          <Row justify="end" className="header-row">
            <a
              href="https://universidadedocombustivel.com/"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="default" size="large" className="university-button">
                <span className="university-controller-pseudo" />
                <span className="university-name">
                  Universidade do Combustível
                </span>
                <span className="university-icon">
                  <UDC key="hood-udc-icon" />
                </span>
              </Button>
            </a>

            <Link id="adicionar-nf-tour" to="/inserir-nf">
              <Button type="primary" size="large" className="header-button">
                <span className="header-button-controller-pseudo" />
                <span className="header-button-name">Inserir Nota Fiscal</span>
                <PlusCircleOutlined />
              </Button>
            </Link>

            <DividerHorizontal />

            {user.imagemPerfil ? (
              <Avatar
                className="header-avatar"
                src={
                  <Image
                    preview={{
                      mask: <span style={{ fontSize: 12 }}>Ver imagem</span>,
                    }}
                    src={user.imagemPerfil.toString()}
                  />
                }
              />
            ) : (
              <Avatar className="header-avatar">
                {!user.imagemPerfil && (
                  <strong>{user.firstName && user.firstName[0]}</strong>
                )}
              </Avatar>
            )}

            <div className="header-information">
              <div>
                <b>Olá, {`${user.firstName} ${user.lastName}`}</b>
              </div>
              <div>{user.email}</div>
            </div>

            <Menu
              id="header-menu-tour"
              mode="horizontal"
              selectedKeys={[location.pathname.replace(/\/+$/, '')]}
            >
              <Menu.Item className="header-item-menu" key="/clube-beneficios">
                <Tooltip
                  arrowPointAtCenter
                  placement="bottom"
                  color="#42BFB7"
                  title="Clube de benefícios"
                >
                  <Link to="/clube-beneficios">
                    <Button
                      type="primary"
                      shape="circle"
                      className="header-item-button"
                      icon={<TrophyOutlined style={{ fontSize: 20 }} />}
                    />
                  </Link>
                </Tooltip>
              </Menu.Item>

              <SubMenu
                key="profile-submenu"
                title={
                  <Button
                    type="primary"
                    shape="circle"
                    className="header-item-button"
                    icon={<SettingOutlined style={{ fontSize: 20 }} />}
                  />
                }
                className="header-item-menu"
              >
                <Menu.Item
                  key="configuracoes-empresa"
                  icon={<SettingOutlined />}
                >
                  <Link to="/configuracoes-empresa">
                    Configurações da empresa
                  </Link>
                </Menu.Item>

                <Menu.Divider />
                <Menu.Item icon={<TagOutlined />}>
                  <Link to="/configuracoes-fornecedor">
                    Configurações do fornecedor
                  </Link>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item
                  icon={<EnvironmentOutlined />}
                  onClick={handleOpenTour}
                >
                  Tutorial guiado
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item icon={<ReadOutlined />}>
                  <Link to="/glossario">Glossário</Link>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="logout"
                  icon={<LogoutOutlined />}
                  onClick={() => logout()}
                >
                  Sair
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Row>
        </Col>
      </Row>
      <TourDashboard isOpen={openTour} closeTour={() => setOpenTour(false)} />
    </Container>
  );
};

export default MainHeader;
