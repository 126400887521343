import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const appRoot = document.getElementById('root');
appRoot?.setAttribute('notranslate', 'true');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  appRoot,
);
