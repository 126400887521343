import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'antd/es/button';
import Modal from 'antd/es/modal';

import { useConfig } from 'src/hooks/config';

import { Container } from './styles';

const ModalExtendPayment: React.FC = () => {
  const { showExtendPaymentModal, updateExtendPaymentModal } = useConfig();

  const history = useHistory();

  const handleCloseModal = () => {
    window.localStorage.setItem('@extendPaymentModal', 'false');
    updateExtendPaymentModal(false);

    history.push('/mais-prazo');
  };

  return showExtendPaymentModal ? (
    <Modal
      title="CONHEÇA O +PRAZO"
      centered
      visible={showExtendPaymentModal}
      onCancel={handleCloseModal}
      maskClosable={false}
      width={600}
      footer={null}
    >
      <Container>
        <h1>Chegou a grande novidade!</h1>
        <p>
          <b>Prorrogue</b> o pagamento das suas compras de combustíveis com{' '}
          <b>as taxas mais competitivas</b> do mercado.
        </p>

        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/iqbumbF3E4I"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="margin-bottom-lg"
        />

        <p>
          <b>+Prazo, + Fluxo de caixa, +Eficiência financeira!</b>
        </p>

        <span>Clique abaixo e conheça agora a nossa nova funcionalidade:</span>

        <div style={{ marginTop: 32 }}>
          <Button onClick={handleCloseModal} size="large" type="primary">
            QUERO +PRAZO AGORA
          </Button>
        </div>
      </Container>
    </Modal>
  ) : null;
};

export default ModalExtendPayment;
