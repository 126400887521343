import React from 'react';
import { FuelInstanceType } from 'src/types/Config';

export interface IRoutesProperties {
  path: string;
  name: string;
  Component: React.LazyExoticComponent<React.FC>;
  fuelHasAccess?: FuelInstanceType[];
}

export const privateRoutesList: IRoutesProperties[] = [
  {
    path: '/',
    name: 'RootPath',
    Component: React.lazy(() => import('src/pages/Dashboard')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    Component: React.lazy(() => import('src/pages/Dashboard')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/margens',
    name: 'Margens',
    Component: React.lazy(() => import('src/pages/Margins')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/cotacoes',
    name: 'Ferramenta de Cotação',
    Component: React.lazy(() => import('src/pages/QuotationTool')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/historico',
    name: 'Histórico',
    Component: React.lazy(() => import('src/pages/History')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/bonificacoes',
    name: 'Bonificações',
    Component: React.lazy(() => import('src/pages/Bonus')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/comparacao-anp',
    name: 'Comparação ANP',
    Component: React.lazy(() => import('src/pages/ANPComparison')),
  },
  {
    path: '/reajustes',
    name: 'Reajustes',
    Component: React.lazy(() => import('src/pages/Adjustments')),
    fuelHasAccess: [1],
  },
  {
    path: '/estoque',
    name: 'Estoque',
    Component: React.lazy(() => import('src/pages/InventoryControl')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/clube-beneficios',
    name: 'Clube de Benefícios',
    Component: React.lazy(() => import('src/pages/BenefitsClub')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/mais-prazo',
    name: '+ Prazo',
    Component: React.lazy(() => import('src/pages/ExtendedPayment')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/configuracoes-empresa',
    name: 'Configurações de Empresa',
    Component: React.lazy(() => import('src/pages/CompanySettings')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/configuracoes-fornecedor',
    name: 'Configurações de Fornecedor',
    Component: React.lazy(() => import('src/pages/DistributorSettings')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/inserir-nf',
    name: 'Inserir Nota Fiscal',
    Component: React.lazy(() => import('src/pages/InsertFile')),
    fuelHasAccess: [1, 2, 3],
  },
  {
    path: '/glossario',
    name: 'Glossário',
    Component: React.lazy(() => import('src/pages/Glossary')),
    fuelHasAccess: [1, 2, 3],
  },
];

export const publicRoutesList: IRoutesProperties[] = [
  {
    path: '/login',
    name: 'Login',
    Component: React.lazy(() => import('src/pages/Login')),
  },
  {
    path: '/cadastrar',
    name: 'Cadastro geral',
    Component: React.lazy(() => import('src/pages/SignUp')),
  },
  {
    path: '/cadastrar-usuario',
    name: 'Cadastro de usuário convidado',
    Component: React.lazy(() => import('src/pages/SignUpUser')),
  },
  {
    path: '/recuperar-senha',
    name: 'Recuperar Senha',
    Component: React.lazy(() => import('src/pages/ForgotPassword')),
  },
  {
    path: '/recuperar-senha/:token',
    name: 'Trocar Senha',
    Component: React.lazy(() => import('src/pages/ChangePassword')),
  },
  {
    path: '/activate',
    name: 'Ativar usuário',
    Component: React.lazy(() => import('src/pages/ActivateUser')),
  },
];
