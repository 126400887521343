import React, { useCallback, useMemo } from 'react';

import Tour, { CustomHelperProps, ReactourStep } from 'reactour';

import { ITourProps } from 'src/types/Onboarding';
import CustomHelper from '../CustomHelper';

/**
 * Componente que realiza o Tour pela Dashboard. No último passo, leva o usuário até a página de
 * Inserir NF e abre o Tour daquela página.
 * @param isOpen Booleano para indicar se o Tour está aberto ou não
 * @param closeTour Função que fecha o Tour
 */
const TourDashboard: React.FC<ITourProps> = ({ isOpen, closeTour }) => {
  const tourConfig: ReactourStep[] = useMemo(() => {
    const steps: ReactourStep[] = [
      {
        selector: '#dashboard-tour',
        content: (
          <div>
            <p>
              Aqui na Visão Geral você encontra um resumo do que está
              acontecendo com suas compras, como o gasto geral com combustíveis,
              variação de preço e dados sobre seu consumo.
            </p>
            <p>
              Essas informações são fundamentais para você conseguir acompanhar
              sua operação e se planejar para futuras compras.
            </p>
          </div>
        ),
        position: [30, 180],
      },
      {
        selector: '#header-menu-tour',
        content: (
          <div>
            <p>
              Nessa parte estão seus dados e de suas empresas, além de algumas
              outras coisinhas como nosso glossário, um espaço para sugestões e
              também um onboarding das funcionalidades da plataforma.
            </p>
            <p>Vale a pena explorar um pouco mais depois!</p>
          </div>
        ),
        position: [window.innerWidth - 410, 70],
      },
      {
        selector: '#adicionar-nf-tour',
        content: (
          <div>
            <p>
              Para você garantir mais economia e utilizar o máximo que a
              Combudata tem para te oferecer, é indispensável que você faça o
              upload de notas fiscais. Quanto mais NF, mais dados e poder de
              decisão.
            </p>
            <p>É só clicar nesse botão e enviar seus arquivos! Vamos lá?</p>
          </div>
        ),
      },
    ];

    steps.unshift({
      selector: '#rdm-tour',
      content: (
        <div>
          <p>
            Esse é o RDM, nosso relatório diário de mercado. Nossos
            especialistas atualizam diariamente com dados relevantes sobre o
            mercado de combustíveis nacional e internacional.
          </p>
          <p>
            Além disso, trazemos também a tendência do mercado para o dia de
            hoje, te orientando para a melhor hora de comprar combustíveis.
          </p>
        </div>
      ),
      position: [window.innerWidth / 2, 130],
    });

    steps.unshift({
      selector: '#aside-menu-tour',
      content: (
        <div>
          <p>
            Aqui você encontra todas as funcionalidades da nossa plataforma.
            Você também pode ocultar esse menu caso queira ter uma visão mais
            ampla do seu dashboard.
          </p>
        </div>
      ),
    });

    return steps;
  }, []);

  const customHelper = useCallback(
    (props: CustomHelperProps) => <CustomHelper {...props} type={0} />,
    [],
  );

  return (
    <Tour
      steps={tourConfig}
      isOpen={isOpen}
      onRequestClose={closeTour}
      showNavigationNumber={false}
      CustomHelper={customHelper}
    />
  );
};

export default TourDashboard;
