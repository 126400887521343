import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
} from 'react';

import api from 'src/services/api';

import notification from 'antd/es/notification';

import { reducer } from 'src/lib/reducers/DistributorReducer';

import {
  IDistributorData,
  IDistributorGroupData,
  State,
} from 'src/types/Distributors';
import { useAuth } from './auth';
import { useConfig } from './config';

interface IDistributorContext {
  fetchDistributors: (params?: { ids: string[] }) => Promise<void>;
  fetchDistributorGroups: () => Promise<void>;
  distributorList: IDistributorData[] | undefined;
  distributorGroupList: IDistributorGroupData[] | undefined;
}

const DistributorContext = createContext<IDistributorContext>(
  {} as IDistributorContext,
);

const initialState: State = {
  distributorList: undefined,
  distributorGroupList: undefined,
};

const DistributorProvider: React.FC = ({ children }) => {
  const [{ distributorList, distributorGroupList }, dispatch] = useReducer(
    reducer,
    initialState,
  );

  const { fuelInstance } = useConfig();
  const { isAuthenticated, mainCompany } = useAuth();

  const fetchDistributors = useCallback(
    async (params = {}): Promise<void> => {
      try {
        const response = await api.get(`/api/usuarios/distribuidores/`, {
          params: {
            combustivel: fuelInstance,
            ...params,
          },
        });

        dispatch({ type: 'UPDATE_DISTRIBUTORS_LIST', payload: response.data });
      } catch (e) {
        notification.error({
          message: 'Erro ao buscar distribuidores!',
        });
      }
    },
    [fuelInstance],
  );

  const fetchDistributorGroups = useCallback(async () => {
    try {
      const response = await api.get(
        '/api/distribuidores/grupos_distribuidor/',
        {
          params: {
            combustivel: fuelInstance,
          },
        },
      );

      dispatch({
        type: 'UPDATE_DISTRIBUTOR_GROUPS_LIST',
        payload: response.data,
      });
    } catch (e) {
      notification.error({
        message: 'Erro ao buscar distribuidores!',
      });
    }
  }, [fuelInstance]);

  useEffect(() => {
    async function initialize() {
      await fetchDistributors({ ids: mainCompany?.id });
      await fetchDistributorGroups();
    }

    if (isAuthenticated) initialize();
  }, [
    isAuthenticated,
    mainCompany?.id,
    fetchDistributorGroups,
    fetchDistributors,
  ]);

  return (
    <DistributorContext.Provider
      value={{
        fetchDistributors,
        fetchDistributorGroups,
        distributorList,
        distributorGroupList,
      }}
    >
      {children}
    </DistributorContext.Provider>
  );
};

function useDistributors(): IDistributorContext {
  const context = useContext(DistributorContext);

  return context;
}

export { DistributorProvider, useDistributors };
