import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
  useEffect,
} from 'react';

import mixpanel from 'mixpanel-browser';
import { formatDistance } from 'date-fns';

import Button from 'antd/es/button';
import notification from 'antd/es/notification';

import { configReducer } from 'src/lib/reducers/ConfigReducer';
import {
  ConfigState,
  FuelInstanceType,
  IConfigContext,
} from 'src/types/Config';

import { useAuth } from 'src/hooks/auth';
import { fuelInstanceTypes } from 'src/utils/dictionaries';

import { Container } from 'src/styles/Privacy';

const ConfigContext = createContext<IConfigContext>({} as IConfigContext);

const initialState: ConfigState = {
  acceptPrivacyPolicy: !!localStorage.getItem('privacyPolicy'),
  fuelInstance: localStorage.getItem('fuelInstance')
    ? (Number(localStorage.getItem('fuelInstance')) as FuelInstanceType)
    : 1,
  loading: false,
  canBuyFuels: [],
  hasBnf: false,
  showInventoryTutorial: true,
  showExtendPaymentModal: false,
};

const ConfigProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, initialState);
  const {
    acceptPrivacyPolicy,
    lastResultId,
    loading,
    fuelInstance,
    canBuyFuels,
    hasBnf,
    showInventoryTutorial,
    showExtendPaymentModal,
  } = state;

  const { user, isAuthenticated, updateCompanyListByFuel } = useAuth();

  const updateInventoryModal = (show: boolean) => {
    dispatch({ type: 'SHOW_MODAL', payload: { showInventoryTutorial: show } });
  };

  const updateExtendPaymentModal = (show: boolean) => {
    dispatch({ type: 'SHOW_MODAL', payload: { showExtendPaymentModal: show } });
  };

  /**
   * Função que salva um novo último resultado de upload no context e no localStorage.
   * @param id Número do Id do último resultado de upload
   */
  const saveResultList = useCallback(
    (id: number | undefined) => {
      if (id) {
        window.localStorage.setItem(`@lastResultId_${user.id}`, id.toString());
      } else window.localStorage.removeItem(`@lastResultId_${user.id}`);

      dispatch({ type: 'UPDATE_LAST_RESULT', payload: { lastResultId: id } });
    },
    [user.id],
  );

  /**
   * Função para setar variável no localStorage que ele aceitou as políticas de privacidade
   * @param privacyState valor true que quando clica em aceitar
   */
  const updatePrivacyPolicy = useCallback((privacyState: boolean): void => {
    dispatch({
      type: 'UPDATE_PRIVACY',
      payload: { acceptPrivacyPolicy: privacyState },
    });
  }, []);

  /**
   * Função que atualiza o tipo de combustível que será visualizado na plataforma atualmente
   * @param fuel Tipo de combustível escolhido
   */
  const updateFuelInstance = useCallback(
    (fuel: 1 | 2 | 3) => {
      if (String(process.env.REACT_APP_ENV) === 'production') {
        mixpanel.track('Alteração de Combustível', {
          Usuário: `${user.firstName} ${user.lastName}`,
          Combustível: fuelInstanceTypes[fuel],
        });
      }

      dispatch({
        type: 'UPDATE_FUEL_INSTANCE',
        payload: { fuelInstance: fuel },
      });
    },
    [user.firstName, user.lastName],
  );

  /**
   * Função que abre ou fecha a notificação sobre Política de privacidade.
   */
  const openNotification = useCallback(() => {
    const key = `open${Date.now()}`;

    const closeNotification = (): void => {
      updatePrivacyPolicy(true);
      notification.close(key);
    };

    const description = (): React.ReactNode => {
      return (
        <Container>
          <div className="row-div">
            <div className="col-div-content">
              <span className="font-size">
                Usamos em nosso sistema cookies para garantir sua privacidade
                enquanto navega pela plataforma, seus dados e informações estão
                seguros e não serão armazenados nesse dispositivo.
              </span>
            </div>
            <div className="col-div-btn">
              <Button
                href="https://combudata.com/politica-de-privacidade/"
                target="_blank"
                rel="noreferrer"
                type="primary"
                ghost
                size="large"
              >
                Política de privacidade
              </Button>
              <Button
                onClick={() => closeNotification()}
                type="primary"
                size="large"
                className="margin-right-xs"
                style={{ width: 215 }}
              >
                Continuar
              </Button>
            </div>
          </div>
        </Container>
      );
    };

    notification.open({
      closeIcon: '',
      className: 'identificar',
      description: description(),
      duration: null,
      key,
      message: (
        <span className="font-size color-primary">
          <b>Nos preocupamos com sua segurança e privacidade.</b>
        </span>
      ),
      style: { width: 'calc(100vw - 80px)', marginLeft: '48px' },
      placement: 'bottomLeft',
    });
  }, [updatePrivacyPolicy]);

  const checkCanBuyFuels = useCallback(async () => {
    try {
      const fuelTypes = new Set<number>();
      user.filiacoes.forEach(filiacao => {
        filiacao.empresa.tiposCombustivel.forEach(cod => {
          fuelTypes.add(cod);
        });
      });

      dispatch({
        type: 'UPDATE_CAN_BUY_FUELS',
        payload: { canBuyFuels: Array.from(fuelTypes) },
      });
    } catch (e) {
      notification.error({
        message: 'Erro ao atualizar opções de combustíveis!',
        description: 'Contate nosso suporte para mais informações',
      });
    }
  }, [user.filiacoes]);

  const checkCanBuyBnf = useCallback(async () => {
    try {
      const checkHasBnf =
        user.filiacoes.findIndex(item => item.empresa.bnf) >= 0;

      dispatch({
        type: 'UPDATE_HAS_BNF',
        payload: { hasBnf: checkHasBnf },
      });
    } catch (e) {
      notification.error({
        message: 'Erro ao atualizar opções de combustíveis!',
        description: 'Contate nosso suporte para mais informações',
      });
    }
  }, [user.filiacoes]);

  useEffect(() => {
    if (isAuthenticated) {
      checkCanBuyFuels();
      checkCanBuyBnf();
    }
  }, [checkCanBuyFuels, checkCanBuyBnf, isAuthenticated]);

  useEffect(() => {
    const privacyStorage = window.localStorage.getItem('privacyPolicy');

    if (!privacyStorage && isAuthenticated) openNotification();
  }, [openNotification, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const fuelInstanceStorage =
        window.localStorage.getItem('fuelInstance') || 1;

      updateFuelInstance(Number(fuelInstanceStorage) as FuelInstanceType);
      updateCompanyListByFuel(Number(fuelInstanceStorage) as FuelInstanceType);
    }
  }, [updateCompanyListByFuel, updateFuelInstance, isAuthenticated]);

  useEffect(() => {
    const date = new Date('2022-04-07T00:00:00');
    const today = new Date();

    const distanceFromToday = formatDistance(today, date, {
      addSuffix: false,
    }).split(' ')[0];

    if (isAuthenticated && Number(distanceFromToday) <= 30) {
      const showExtendPaymentModalStorage =
        window.localStorage.getItem('@extendPaymentModal') || 'true';

      if (showExtendPaymentModalStorage === 'true') {
        updateExtendPaymentModal(true);
      }
    }
  }, [isAuthenticated]);

  return (
    <ConfigContext.Provider
      value={{
        loading,
        updatePrivacyPolicy,
        acceptPrivacyPolicy,
        saveResultList,
        lastResultId,
        updateFuelInstance,
        fuelInstance,
        checkCanBuyFuels,
        canBuyFuels,
        checkCanBuyBnf,
        hasBnf,
        updateInventoryModal,
        showInventoryTutorial,
        updateExtendPaymentModal,
        showExtendPaymentModal,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

function useConfig(): IConfigContext {
  const context = useContext(ConfigContext);

  return context;
}

export { ConfigProvider, useConfig };
