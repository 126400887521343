import styled from 'styled-components';

interface IContainerProps {
  menuMobileIsOpen: boolean;
}

export const Container = styled.div<IContainerProps>`
  /* restringe área do menu lateral baseado no grid definido no layout */
  grid-area: SB;

  background-color: #ffffff;

  position: relative;

  .div-button-open-close {
    top: 4px;
    right: -16px;
    position: absolute;
    z-index: 99;
  }

  .menu-content {
    padding-top: 50px;

    /* cálculo para altura do sidebar */
    /*define que terá scrollbar apenas quando o sidebar ultrapassar o tamanaho da tela visível */
    height: calc(100vh - 156px);
    overflow-y: auto;

    /* personalização da scrollbar */
    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #8a8a8a;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: #b1b1b1;
    }
  }

  .menu-policy {
    height: 96px;
    border-top: 1px solid #f7f7f7;
    padding-top: 4px;
  }

  .link-name {
    display: initial;
  }

  .link-name-close {
    display: none;
  }

  /* css para itens do menu lateral */
  .ant-menu-item {
    margin-top: 0px;
  }

  .sibebar-icon {
    font-size: 20px;
  }

  img.logo-cm {
    display: unset;
    margin-bottom: 6px;
    margin-right: 10px;
  }

  /* css para itens do submenu lateral */
  .ant-menu-sub {
    font-weight: 200;
  }

  @media (max-width: 767px) {
    position: fixed;
    z-index: 99;

    height: ${props => (props.menuMobileIsOpen ? '100vh' : '60px')};
    width: ${props => (props.menuMobileIsOpen ? '275px' : '73px')};

    box-shadow: ${props =>
      props.menuMobileIsOpen ? '8px 0px 18px rgba(0, 0, 0, 0.5);' : 'none'};

    overflow: hidden;

    .menu-content {
      padding-top: 20px;
      border-top: 1px solid #f7f7f7;
    }

    .div-button-open-close {
      display: none;
    }

    .button-toggle-menu {
      margin: 10px 15px;
      height: 40px;
    }

    .button-toggle-close {
      margin-left: 212px;
    }

    .link-name-close {
      display: initial;
    }

    .initial-action {
      display: inline-flex;
    }
  }

  @media (min-width: 768px) {
    .initial-action,
    .button-toggle {
      display: none;
    }
  }

  a.no-hover,
  a.no-hover:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.bodyTextColor};
  }
`;
