import React from 'react';

import { AuthProvider } from './auth';
import { FilterProvider } from './filter';
import { CityProvider } from './cities';
import { DistributorProvider } from './distributors';
import { MenuProvider } from './menu';
import { ConfigProvider } from './config';
import { QuotationProvider } from './quotation';

/**
 * Componente que junta os dois providers presentes na aplicação
 */
const AppProvider: React.FC = ({ children }) => {
  return (
    <AuthProvider>
      <ConfigProvider>
        <MenuProvider>
          <FilterProvider>
            <DistributorProvider>
              <QuotationProvider>
                <CityProvider>{children}</CityProvider>
              </QuotationProvider>
            </DistributorProvider>
          </FilterProvider>
        </MenuProvider>
      </ConfigProvider>
    </AuthProvider>
  );
};

export default AppProvider;
