import { createGlobalStyle } from 'styled-components';

import freemiumIcon from 'src/assets/icons/freemium.svg';
import silverIcon from 'src/assets/icons/prata.svg';
import goldIcon from 'src/assets/icons/ouro.svg';
import diamondIcon from 'src/assets/icons/diamante.svg';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

    font-family: 'Cairo', sans-serif;
  }

  body {
    background-color: #e5e5e5 !important;
    -webkit-font-smoothing: antialiased !important;
    font-weight: 600;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }


  .ant-card-extra, .ant-card-head-title, .ant-btn-primary, .ant-modal-title {
    font-weight: 600;
    text-transform: uppercase;
  }

  .ant-card-head-title {
    font-size: 18px;
  }

  .ant-card {
    box-shadow: -7px 7px 7px -1px rgba(0, 0, 0, 0.15) !important;
  }

  #root {
    height: 100vh;
  }

  .important-nb {
    font-size: 26px;
    font-weight: 600;
    color: #525252;
  }

  .important-good-nb {
    font-size: 36px;
    font-weight: 600;
    color: ${props => props.theme.colors.primary};
  }

  .important-bad-nb {
    font-size: 36px;
    font-weight: 600;
    color:
    ${props => props.theme.colors.error};
  }

  .extra-menu-content {
    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }

  .freemiumIcon {
    background: url(${freemiumIcon}) no-repeat border-box top center;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 12px;
  }

  .silverIcon {
    background: url(${silverIcon}) no-repeat border-box top center;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 12px;
  }

  .goldIcon {
    background: url(${goldIcon}) no-repeat border-box top center;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 12px;
  }

  .diamondIcon {
    background: url(${diamondIcon}) no-repeat border-box top center;
    background-size: cover;
    width: 10px;
    height: 10px;
    margin-left: 12px;
  }

  .option-flex {
    .ant-select-item-option-content {
      display: flex;
      align-items: center;
    }
  }
`;
