import theme from 'src/styles/theme';

export const rdmTypes = {
  1: 'Forte Alta - Mantenha estoque máximo',
  2: 'Leve Alta - Mantenha estoque acima da média',
  3: 'Estabilidade - Mantenha estoque médio',
  4: 'Leve Queda - Mantenha estoque abaixo da média',
  5: 'Forte Queda - Mantenha estoque mínimo',
};

export const rdmTypesFreemium = {
  1: 'Forte Alta',
  2: 'Leve Alta',
  3: 'Estável',
  4: 'Leve Baixa',
  5: 'Forte Baixa',
};

export const rdmEtanolTypes = {
  1: 'Alta - Mantenha estoque acima da média',
  2: 'Estabilidade - Mantenha estoque médio',
  3: 'Queda - Mantenha estoque abaixo da média',
};

export const rdmEtanolTypesFreemium = {
  1: 'Alta',
  2: 'Estabilidade',
  3: 'Queda',
};

export const tendencyColor = {
  1: theme.colors.error,
  2: theme.colors.attention,
  3: theme.colors.warning,
  4: theme.colors.success,
  5: theme.colors.success,
};

export const tendencyColorEtanol = {
  1: theme.colors.error,
  2: theme.colors.warning,
  3: theme.colors.success,
};

export const statusMember = {
  0: 'Inativo',
  1: 'Ativo',
};

export const roleType = {
  0: 'Usuário',
  1: 'Administrador',
  2: 'SuperAdmin',
  3: 'Gerente de Contas',
};

export const freightType = {
  0: 'CIF',
  1: 'FOB',
};

export const fuelType = {
  1: 'Diesel S10',
  2: 'Diesel S500',
  3: 'Gasolina',
  5: 'Etanol',
};

export const qualification = { 1: 'Comum', 2: 'Aditivado' };

export const fuelCode = {
  1111: 'Diesel S10 Comum',
  1112: 'Diesel S10 Comum c/ Beneficio Fiscal',
  1211: 'Diesel S10 Aditivado',
  1212: 'Diesel S10 Aditivado c/ Beneficio Fiscal',
  2111: 'Diesel S500 Comum',
  2112: 'Diesel S500 Comum c/ Beneficio Fiscal',
  2211: 'Diesel S500 Aditivado',
  2212: 'Diesel S500 Aditivado c/ Beneficio Fiscal',
  3111: 'Gasolina C Comum',
  3211: 'Gasolina C Aditivada',
  5111: 'Etanol Hidratado',
  5211: 'Etanol Hidratado Aditivado',
};

export const supplyMode = {
  0: 'Spot (cota e compra de quem quiser a qualquer momento)',
  1: 'AFF (não há obrigação contratual, é um acordo verbal)',
  2: 'Contratado com Contrato de Exclusividade de Fornecimento',
};

// 0: 'Regular' -> Não
// 1: 'BNF' -> Sim
// 2: 'BNF Extra' -> Muda apenas no admin
export const icms = {
  0: 'Não',
  1: 'Sim',
};

export const tipoConfig = [
  'Juros',
  'Margem operacional definida',
  'Bonificação',
];

export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const operationArea = [
  {
    value: 13,
    label: 'Agricultura',
    name: '',
  },
  {
    value: 1,
    label: 'Cimenteira',
    name: '',
  },
  {
    value: 2,
    label: 'Comércio',
    name: '',
  },
  {
    value: 3,
    label: 'Construção Civil',
    name: '',
  },
  {
    value: 14,
    label: 'Geração de energia elétrica',
    name: '',
  },
  {
    value: 20,
    label: 'Hospital',
    name: '',
  },
  {
    value: 7,
    label: 'Indústria',
    name: '',
  },
  {
    value: 19,
    label: 'Instituição de Ensino',
    name: '',
  },
  {
    value: 4,
    label: 'Mineração',
    name: '',
  },
  {
    value: 5,
    label: 'Pecuária',
    name: '',
  },
  {
    value: 6,
    label: 'Posto Revendedor de Combustível',
    name: '',
  },
  {
    value: 21,
    label: 'Serviços',
    name: '',
  },
  {
    value: 18,
    label: 'Shopping',
    name: '',
  },
  {
    value: 8,
    label: 'Transporte Aéreo',
    name: '',
  },
  {
    value: 9,
    label: 'Transporte Ferroviário de Carga',
    name: '',
  },
  {
    value: 10,
    label: 'Transporte Marítimo de Carga',
    name: '',
  },
  {
    value: 11,
    label: 'Transporte Rodoviário de Carga',
    name: '',
  },
  {
    value: 12,
    label: 'Transporte Rodoviário de Passageiro',
    name: '',
  },
  {
    value: 15,
    label: 'Transporte Marítimo de Passageiro',
    name: '',
  },
  {
    value: 17,
    label: 'Tratamento de Resíduos',
    name: '',
  },
  {
    value: 16,
    label: 'Outras',
    name: '',
  },
];

export const statusUploadResult = [
  {
    status: 'Em processamento',
    color: theme.colors.bodyTextColor,
  },
  {
    status: 'Concluído',
    color: theme.colors.success,
  },
  {
    status: 'Erro ao carregar!',
    color: theme.colors.error,
  },
  {
    status: 'Devolução',
    color: theme.colors.bodyTextColor,
  },
];

export const planTypes = {
  0: 'Freemium',
  1: 'Prata',
  2: 'Ouro',
  3: 'Diamante',
};

export const supplierCompanyType = {
  1: 'Distribuidor',
  2: 'TRR',
  3: 'Posto Revendedor',
  4: 'Usina de Etanol',
};

export const fuelInstanceTypes = {
  1: '.diesel',
  2: '.gasolina',
  3: '.etanol',
};

export const integrationTypes = {
  1: 'EXCELbr',
  2: 'Gilbarco Veeder-Root',
};

export const goldenPages = ['/reajuste', '/bonificacoes'];

export const extendPaymentStatus = [
  {
    value: 0,
    label: 'Operável',
    color: theme.colors.success,
    description: '',
  },
  {
    value: 1,
    label: 'Em análise',
    color: theme.colors.attention,
    description: 'Nossa equipe está analisando a sua solicitação.',
  },
  {
    value: 2,
    label: 'Aguardando aceite',
    color: theme.colors.attention,
    description: 'Enviamos a proposta e estamos aguardando o seu aceite.',
  },
  {
    value: 3,
    label: 'Em processamento',
    color: theme.colors.attention,
    description: 'Estamos dando andamento no pagamento do boleto original.',
  },
  {
    value: 4,
    label: 'Aguardando pagamento',
    color: theme.colors.attention,
    description: 'Aguardamos o pagamento do seu novo boleto.',
  },
  {
    value: 5,
    label: 'Solicitação reprovada',
    color: theme.colors.error,
    description: 'Sua solicitação não foi aceita.',
  },
  {
    value: 6,
    label: 'Proposta rejeitada',
    color: theme.colors.error,
    description: 'Sua empresa rejeitou a nossa proposta.',
  },
  {
    value: 7,
    label: 'Proposta expirada',
    color: theme.colors.error,
    description: 'A data de resposta da sua proposta expirou.',
  },
  {
    value: 8,
    label: 'Pagamento em atraso',
    color: theme.colors.error,
    description: 'Não identificamos o pagamento do seu novo boleto.',
  },
  {
    value: 9,
    label: 'Efetivado',
    color: theme.colors.primary,
    description: 'Operação efetivada.',
  },
];
