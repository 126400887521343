import React, { useEffect } from 'react';

import ConfigProvider from 'antd/es/config-provider';
import ptBR from 'antd/lib/locale/pt_BR';

import 'date-fns/locale/pt-BR';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ReactGA from 'react-ga';

import { ThemeProvider } from 'styled-components';

import mixpanel from 'mixpanel-browser';

import AppProvider from 'src/hooks/index';

import Routes from './routes';

import GlobalStyles from './styles/global';
import theme from './styles/theme';
import './styles/defaultMargins.css';
import './styles/defaultPaddings.css';

import './App.less';

const queryClient = new QueryClient();

const App: React.FC = () => {
  useEffect(() => {
    if (
      ['production', 'staging', 'sales'].includes(
        String(process.env.REACT_APP_ENV),
      )
    ) {
      Sentry.init({
        dsn: 'https://85b3cb4cfbdd486b8e2a6e372b5162e0@o375086.ingest.sentry.io/5593148',
        integrations: [new Integrations.BrowserTracing()],
        environment: 'production',
        tracesSampleRate: 1.0,
      });
    }

    if (String(process.env.REACT_APP_ENV) === 'production') {
      ReactGA.initialize('UA-147525283-1');
      ReactGA.pageview(window.location.pathname + window.location.search);

      mixpanel.init('c5aa257617382d37a9d26c4f25c41706', {
        disable_cookie: true,
        disable_persistence: true,
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={ptBR}>
          <AppProvider>
            <Routes />
          </AppProvider>
        </ConfigProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
      <GlobalStyles />
    </ThemeProvider>
  );
};

export default App;
