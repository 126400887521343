/**
 * Formata o valor conforme o usuário vai digitando, colocando ponto de milhar antes de cada 3 dígitos
 * @param value valor a ser formatado
 * @returns valor do tipo string com a formatação aplicada
 */
export const handleThousandPoint = (
  value: string | number | undefined,
): string => {
  let v = value?.toString();
  v = v?.replace(/\D/g, ''); // permite digitar apenas numero
  v = v?.replace(/(\d{1})(\d{12})$/, '$1.$2'); // coloca ponto antes dos ultimos 15 dígitos
  v = v?.replace(/(\d{1})(\d{9})$/, '$1.$2'); // coloca ponto antes dos ultimos 11 dígitos
  v = v?.replace(/(\d{1})(\d{6})$/, '$1.$2'); // coloca ponto antes dos ultimos 8 dígitos
  v = v?.replace(/(\d{1})(\d{3})$/, '$1.$2'); // coloca ponto antes dos ultimos 5 dígitos

  return v || '';
};

/**
 * Formata o valor conforme o usuário vai digitando, colocando ponto de milhar antes de cada 3 dígitos antes da vírgula,
 * e insere vírgula antes dos 2 últimos dígitos
 * @param {string} value - valor a ser formatado
 * @returns valor do tipo string com a formatação aplicada
 */
export const handleCurrency = (
  value: string | number | undefined,
  decimals = 2,
): string => {
  const decimalRegex = new RegExp(`(\\d{1})(\\d{1,${decimals}})$`);
  const thousandPointRegex = new RegExp(`(\\d{1})(\\d{${decimals + 3}})$`);

  let v = value?.toString();
  v = v?.replace(/\D/g, ''); // permite digitar apenas numero
  v = v?.replace(/(\d{1})(\d{15})$/, '$1.$2'); // coloca ponto antes dos ultimos 15 dígitos
  v = v?.replace(/(\d{1})(\d{11})$/, '$1.$2'); // coloca ponto antes dos ultimos 11 dígitos
  v = v?.replace(/(\d{1})(\d{8})$/, '$1.$2'); // coloca ponto antes dos ultimos 8 dígitos
  v = v?.replace(thousandPointRegex, '$1.$2'); // coloca ponto antes dos ultimos 5 dígitos
  v = v?.replace(decimalRegex, '$1,$2'); // coloca virgula antes dos ultimos 2 dígitos

  return v || '';
};

/**
 * Formata a string com base no padrão para telefones. O hífen muda lugar caso seja celular ou telefone
 * fixo
 * @param value valor a ser formatado
 * @returns valor do tipo string com a formatação aplicada
 */
export const handlePhoneNumber = (value: string): string => {
  let v = value;
  v = v.replace(/\D/g, '');

  if (v.length <= 10) {
    v = v.replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
  } else if (v.length <= 11) {
    v = v.replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
  }

  return v;
};

/**
 * Converte os valores com números de casas
 * @param value valor para ser formatado
 * @param minimumFractionDigits  número mínimo de casas decimais
 * @param maximumFractionDigits  número máximo de casas
 * @returns valor do tipo string com a formatação aplicada
 */
export const handleFormatValues = (
  value: number | string,
  minimumFractionDigits = 2,
  maximumFractionDigits = 4,
): string => {
  const formattedValue = Number(value).toLocaleString('pt-BR', {
    minimumFractionDigits,
    maximumFractionDigits,
  });

  return formattedValue;
};

/**
 * Converte um Array de valores em uma string de parâmetros, na forma: "paraName=paramValue&"
 * @param paramValue Array com todos os valores a serem convertidos
 * @param paramName Nome do parâmetro que será convertido em string
 * @returns Retorna uma string com os parâmetros convertidos, na forma "paraName=paramValue&"
 */
export const handleGenerateURLSearchParams = (
  paramValue: Array<string | number>,
  paramName: string,
  searchParam?: URLSearchParams,
): string => {
  let url = '';
  paramValue.forEach(item => {
    if (item !== '' && item) {
      url += `${paramName}=${item}`;
      searchParam?.append(paramName, item.toString());
    }
  });

  return url;
};

/**
 * Função para transformar parâmetro de pascal case para underscore case
 * @param value Valor a ser formatado
 * @returns retorna valor formatado para underscore case
 */
export const handlePascalCaseToUnderscoreCase = (value: string): string => {
  return value
    .replace(/\.?([A-Z])/g, (x, y) => {
      return `_${y.toLowerCase()}`;
    })
    .replace(/^_/, '');
};
