import React, { createContext, useContext, useCallback } from 'react';

import axios from 'axios';
import api from 'src/services/api';

import notification from 'antd/es/notification';

import { IStateData, ICityData } from 'src/types/CityState';

interface ICityContext {
  getStates(): Promise<IStateData[]>;
  getCities(uf: number | string): Promise<ICityData[]>;
}

const CityContext = createContext<ICityContext>({} as ICityContext);

const CityProvider: React.FC = ({ children }) => {
  /**
   * Busca a lista de estados brasileiros
   */
  const getStates = useCallback(async (): Promise<IStateData[]> => {
    try {
      const response = await api.get('/api/cidades/estados/');

      return response.data as IStateData[];
    } catch (e) {
      if (axios.isAxiosError(e)) {
        notification.error({
          message: 'Erro ao buscar estados',
          description: e.response && e.response.data,
        });
      }

      return [];
    }
  }, []);

  /**
   * Função que busca as cidades de um determinado estado
   * @param uf Id ou sigla do estado de onde são as cidades
   */
  const getCities = useCallback(
    async (uf: number | string): Promise<ICityData[]> => {
      try {
        const response = await api.get(`/api/cidades/${uf}/`);

        return response.data as ICityData[];
      } catch (e) {
        if (axios.isAxiosError(e)) {
          notification.error({
            message: 'Erro ao buscar cidades',
            description: e.response && e.response.data,
          });
        }
        return [];
      }
    },
    [],
  );

  return (
    <CityContext.Provider value={{ getStates, getCities }}>
      {children}
    </CityContext.Provider>
  );
};

function useCities(): ICityContext {
  const context = useContext(CityContext);

  return context;
}

export { CityProvider, useCities };
