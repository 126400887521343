import React from 'react';

import Button from 'antd/es/button';
import Result from 'antd/es/result';

const ErrorBoundary: React.FC = () => {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Desculpe, algo deu errado. Contate nosso suporte."
      extra={
        <Button
          type="primary"
          href="https://wa.me/5541992849689/?text=Olá%20preciso%20de%20suporte%20na%20Combudata"
          target="_blank"
          rel="noreferrer"
        >
          Contato
        </Button>
      }
    />
  );
};

export default ErrorBoundary;
