import {
  IDistributorGroupData,
  IDistributorData,
  State,
  Action,
} from 'src/types/Distributors';

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'UPDATE_DISTRIBUTORS_LIST': {
      const newState = { ...state };
      const payload = action.payload as IDistributorData[];

      newState.distributorList = payload;

      return newState;
    }

    case 'UPDATE_DISTRIBUTOR_GROUPS_LIST': {
      const newState = { ...state };
      const payload = action.payload as IDistributorGroupData[];

      newState.distributorGroupList = payload;

      return newState;
    }

    default: {
      return state;
    }
  }
}
