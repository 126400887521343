import { IMemberCompany, IUserData } from 'src/types/User';
import { AuthAction, AuthState } from 'src/types/Auth';
import { arrayContainsSome } from 'src/utils/helper';

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'LOADING': {
      const newState = { ...state };
      newState.loading = true;

      return newState;
    }

    case 'AUTHENTICATE': {
      const newState = { ...state };

      const payload = action.payload?.data;

      newState.loading = false;
      newState.isAuthenticated = true;
      newState.mainCompany = payload?.filiacaoPrincipal?.empresa;
      newState.user = payload || ({} as IUserData);
      newState.firstLogin =
        payload?.filiacoes.length === 1 &&
        payload?.filiacoes[0].empresa.tiposCombustivel.length === 0;

      const goldCompanies =
        action.payload && action.payload.data
          ? action.payload.data?.filiacoes?.filter(
              (item: IMemberCompany) => item.empresa.plano === 2,
            )
          : [];

      newState.goldCompanies = goldCompanies;

      const freemiumCompanies =
        action.payload && action.payload.data
          ? action.payload.data?.filiacoes?.filter(
              (item: IMemberCompany) => item.empresa.plano === 0,
            )
          : [];

      newState.freemiumCompanies = freemiumCompanies;

      return newState;
    }

    case 'UPDATE_USER_INFO': {
      const newState = { ...state };

      const payload = action.payload?.data;
      newState.user = payload || ({} as IUserData);

      return newState;
    }

    case 'AUTHENTICATE_FAIL': {
      const newState = { ...state };

      newState.loading = false;
      newState.isAuthenticated = false;

      return newState;
    }

    case 'UPDATE_FREEMIUM_STATE': {
      const newState = { ...state };
      newState.isFreemium = !!action.payload?.isFreemium;

      return newState;
    }

    case 'UPDATE_COMPANY_LIST_BY_FUEL': {
      const newState = { ...state };
      const fuelInstance = action.payload?.fuelInstance;

      const goldCompanies = newState.user.filiacoes.filter(
        item => item.empresa.plano > 0,
      );
      const freemiumCompanies = newState.user.filiacoes.filter(
        item => item.empresa.plano === 0,
      );

      let fuelTypes = [0];

      if (fuelInstance === 1) {
        fuelTypes = [1, 2];
      }

      if (fuelInstance === 2) {
        fuelTypes = [3];
      }

      if (fuelInstance === 3) {
        fuelTypes = [5];
      }

      const goldCompaniesByFuel = goldCompanies.filter(item => {
        return arrayContainsSome(fuelTypes, item.empresa.tiposCombustivel);
      });

      newState.goldCompanies = goldCompaniesByFuel;

      const freemiumCompaniesByFuel = freemiumCompanies.filter(item => {
        return arrayContainsSome(fuelTypes, item.empresa.tiposCombustivel);
      });

      newState.freemiumCompanies = freemiumCompaniesByFuel;

      return newState;
    }

    default:
      return state;
  }
}
