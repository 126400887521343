import styled from 'styled-components';
import Logo from 'src/assets/logos/logo-mono.svg';

import ArrowIcon from 'src/assets/icons/arrow-icon.svg';

interface IFuelSelectedProps {
  fuelInstance: string;
}

export const Container = styled.div`
  position: relative;
  overflow: visible;

  display: flex;
  align-items: center;

  /* css para estilização e posicionamento da logo da CD */
  .logo {
    /* background: url(${Logo}) no-repeat center center;
    background-size: 230px; */

    height: 60px;
    width: 230px;
    margin: auto;

    margin-right: 3px;
  }

  img.logo-fuel {
    width: 100%;
    max-width: 70px;

    height: auto;
    margin-bottom: 3px;
  }

  img.arrow-icon {
    margin-left: 20px;
  }

  button {
    display: flex;
    align-items: center;

    height: 100%;

    border: 0;
    outline: 0;

    background-color: white;

    :hover {
      cursor: pointer;
    }
  }

  @media (max-width: 767px) {
    margin-left: 58px;

    .logo {
      width: 180px;
    }
  }
`;

export const SelectedFuel = styled.div<IFuelSelectedProps>`
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  color: #262e34;
  height: 60px;

  margin-top: -5px;
  margin-right: 15px;

  @media (max-width: 767px) {
    font-size: 28px;
  }
`;

export const ArrowDown = styled.div`
  background: url(${ArrowIcon}) no-repeat center center;
  background-size: 20px;
  height: 60px;
  width: 60px;
`;

export const FuelPanelOptions = styled.div`
  position: absolute;
  background-color: white;
  width: 130px;

  font-weight: 600;
  font-size: 18px;
  color: #262e34;

  box-shadow: -5px 5px 7px -1px rgba(0, 0, 0, 0.15);

  right: 5px;
  bottom: -95px;
  z-index: 999;

  div:first-child {
    border-bottom: 1px solid #f2f2f2;
  }
`;

export const FuelOption = styled.div`
  :hover {
    cursor: pointer;
    background-color: #ccc;
  }
  padding: 12px;
`;
