import styled from 'styled-components';

export const Container = styled.div`
  div.row-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  div.col-div-content {
    padding-right: 16px;
  }

  div.col-div-btn {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1024px) {
    div.row-div {
      flex-wrap: wrap;
    }

    div.col-div-content {
      padding-right: 0px;
      margin-bottom: 12px;
    }
  }
`;
