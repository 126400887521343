import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { format, startOfToday, subDays } from 'date-fns';

import { IFilterForm, IFilterContext } from 'src/types/Filter';
import { arrayContainsSome } from 'src/utils/helper';

import { useAuth } from './auth';
import { useConfig } from './config';

const FilterContext = createContext<IFilterContext>({} as IFilterContext);

const FilterProvider: React.FC = ({ children }) => {
  const { mainCompany, isAuthenticated } = useAuth();
  const { canBuyFuels } = useConfig();

  const [filterValues, setFilterValues] = useState<IFilterForm>(() => {
    return {
      dataAfter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      dataBefore: format(startOfToday(), 'yyyy-MM-dd'),
      empresa: mainCompany ? [String(mainCompany.id)] : [''],
      distribuidor: [],
      tipoCombustivel: [],
      date: [subDays(new Date(), 30), startOfToday()],
      hasCompanyForFuelInstance: true,
    };
  });

  const updateFilterValues = useCallback((values: IFilterForm): void => {
    setFilterValues(values);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      let fuelTypes = [0];
      let tipoCombustivel = ['1'];
      const fuelInstance = Number(window.localStorage.getItem('fuelInstance'));

      if (fuelInstance === 1) {
        fuelTypes = [1, 2];
      }

      if (fuelInstance === 2) {
        fuelTypes = [3];
        tipoCombustivel = ['2'];
      }

      if (fuelInstance === 3) {
        fuelTypes = [5];
        tipoCombustivel = ['3'];
      }

      setFilterValues({
        hasCompanyForFuelInstance: arrayContainsSome(fuelTypes, canBuyFuels),
        dataAfter: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
        dataBefore: format(startOfToday(), 'yyyy-MM-dd'),
        empresa: mainCompany ? [String(mainCompany.id)] : [''],
        distribuidor: [],
        tipoCombustivel,
        date: [subDays(new Date(), 30), startOfToday()],
      });
    }
  }, [isAuthenticated, canBuyFuels, mainCompany]);

  return (
    <FilterContext.Provider value={{ filterValues, updateFilterValues }}>
      {children}
    </FilterContext.Provider>
  );
};

const useFilter = (): IFilterContext => {
  const context = useContext(FilterContext);

  return context;
};

export { FilterProvider, useFilter };
