import styled from 'styled-components';

interface IContentVariationProps {
  value: string | number;
}

export const Container = styled.div`
  /* restringe área do componente RDM baseado no grid definido no layout */
  grid-area: RDM;

  background-color: #000000;
  padding: 14px 8px;

  display: flex;

  align-items: center;

  /* css para elementos do componente */
  .rdm-row {
    color: white;
    font-size: 12px;

    flex: 1;
  }
`;

export const RdmContent = styled.div`
  display: flex;
  line-height: 15px;

  align-items: center;
`;

export const ContentValue = styled.div`
  font-weight: 500;

  display: flex;
`;

export const ContentVariation = styled.div<IContentVariationProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 4px;
  color: ${props =>
    props.value >= 0 ? props.theme.colors.primary : props.theme.colors.error};
`;

export const ContentTitle = styled.div`
  font-weight: 600;

  margin-right: 4px;
`;

export const RdmDivider = styled.div`
  height: 16px;
  border-left: 1px solid #ffffff;
  background-color: white;

  margin: 0 8px;
`;

export const RoundIcon = styled.div`
  min-width: 15px;
  height: 15px;

  border-radius: 50%;
  background-color: ${props => props.color};

  margin-right: 4px;
`;
