import styled from 'styled-components';

import Logo from 'src/assets/logos/logo-CD-full.png';

export const Container = styled.div`
  /* restringe área do cabeçalho baseado no grid definido no layout */
  grid-area: MH;

  background-color: #ffffff;
  border-bottom: 1px solid #f7f7f7;

  padding: 0 16px;

  .initial-link {
    display: flex;
    align-items: center;

    /* css para estilização e posicionamento da logo da CD */
    img.logo {
      height: auto;
      width: 100%;
      max-width: 275px;

      margin-right: 3px;
    }

    img.logo-fuel {
      width: 100%;
      max-width: 70px;

      height: auto;
      margin-bottom: 3px;
    }

    img.arrow-icon {
      margin-left: 20px;
    }
  }

  /* css para elementos do cabeçalho */
  .header-row {
    height: 60px;
  }

  .header-button {
    margin: 10px;
  }

  .university-button {
    text-transform: uppercase;
    color: #ffffff ;
    background-color: #FC7021;
    border-color: #FC7021;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 10px;
  }

  .university-button:active {
    color: #fff;
    border-color: #D6601C;
    background: #D6601C;
  }

  .university-button:hover, .university-button:focus {
    color: #fff;
    border-color: #FF9554;
    background: #FF9554;
  }

  span.university-icon {
    margin-left: 8px;
    display: inline-flex;
  }

  .header-icon {
    font-size: 23px;
    color: #b1b1b1;
    margin: 0;
  }

  .header-icon > svg {
    margin: 18px 20px;
  }

  .header-avatar {
    margin: 10px;
  }

  .header-information {
    align-self: center;
    margin: 0 15px 0 0;
  }

  .ant-avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
  }

  .ant-menu-item {
    padding: 0 !important;
  }

  .ant-menu-submenu {
    padding: 0 !important;
  }

  .ant-menu-item::after,
  .ant-menu-submenu::after {
    right: 0;
    left: 0;
  }

  .header-item-menu {
    height: 60px;
    width: 60px;
  }

  .header-item-button {
    width: 40px;
    height: 40px;
    margin: 10px;
  }

  .ant-menu-horizontal {
    border-bottom: 0px;
  }

  @media (max-width: 767px) {
    .logo {
      background-size: 170px;

      width: 198px;
      border-bottom: none;
    }

    .logo-close {
      background: url(${Logo}) no-repeat center center;
      background-size: 170px;

      width: 198px;
      border-bottom: none;
    }
  }

  @media (max-width: 1000px) {
    .header-avatar {
      display: none;
    }

    .header-information {
      display: none;
    }
  }

  @media (max-width: 1373px) {
    .university-button {
      .university-name {
        display: none;
      }

      .university-controller-pseudo::after {
        content: 'UDC';
      }
    }

    .header-button {
      .header-button-name {
        display: none;
      }

      .header-button-controller-pseudo::after {
        content: 'NF';
      }
    }
  }

  @media (max-width: 972px) {
    .buttons-row {
      flex-flow: initial;
    }

    .header-row {
      flex-flow: initial;
    }
  }
`;

export const DividerHorizontal = styled.div`
  border: 1px solid ${props => props.theme.colors.bodyTextColor};
  margin: 10px 10px;
`
