/* eslint-disable import/no-duplicates */
import ptBR from 'date-fns/locale/pt-BR';
import { addDays, format, getYear } from 'date-fns';

import { handleGenerateURLSearchParams } from 'src/utils/formatters';

import { IFilterForm } from 'src/types/Filter';

export const generateUrlSearchParamsWithFilter = (
  filterValues: IFilterForm,
): URLSearchParams => {
  const stringParams = new URLSearchParams();

  handleGenerateURLSearchParams(
    filterValues.distribuidor,
    'distribuidor',
    stringParams,
  );
  handleGenerateURLSearchParams(filterValues.empresa, 'empresa', stringParams);
  handleGenerateURLSearchParams(
    filterValues.tipoCombustivel,
    'tipo_combustivel',
    stringParams,
  );

  const dataAfter = filterValues?.dataAfter
    ? addDays(new Date(filterValues.dataAfter), 1)
    : filterValues.date[0];
  handleGenerateURLSearchParams(
    [format(dataAfter, 'yyyy-MM-dd')],
    'data_after',
    stringParams,
  );

  const dataBefore = filterValues?.dataBefore
    ? addDays(new Date(filterValues.dataBefore), 1)
    : filterValues.date[1];
  handleGenerateURLSearchParams(
    [format(dataBefore, 'yyyy-MM-dd')],
    'data_before',
    stringParams,
  );

  return stringParams;
};

/**
 * Função que faz a formatação da data através do período e o agrupamento recebidos. Caso o agrupamento
 * for por mês, irá retornar uma string do tipo "Ago/2021". Caso for pelo ano, retorna o número do
 * ano como string. Caso for outro tipo de agrupamento, retorna uma data com a formatação padrão
 * em pt-BR dd/mm/yyyy.
 * @param period Período de datas para extrair a formatação correta
 * @param groupBy Agrupamento que define o tipo de formatação da data. Poder por Mês, Ano e data
 * completa
 * @returns Retorna uma string com a data formatada conforme o agrupamento.
 */
export const getDateOrPeriod = (period: string[], groupBy: string): string => {
  switch (groupBy) {
    case 'group_by=month': {
      return format(addDays(new Date(period[0]), 1), 'MMM/yyyy', {
        locale: ptBR,
      });
    }

    case 'group_by=year': {
      const year = getYear(addDays(new Date(period[0]), 1));

      return year.toString();
    }

    default:
      return format(addDays(new Date(period[0]), 1), 'dd/MM/yyyy');
  }
};

export const arrayContainsSome = (
  values: number[],
  array: number[],
): boolean => {
  return values.some(item => array.includes(item));
};

export const capitalizeString = (str: string): string => {
  const result = str.charAt(0).toUpperCase() + str.slice(1);

  return result;
};
