import styled from 'styled-components';

export const Container = styled.div`
  /* restringe área do conteúdo baseado no grid definido no layout */
  grid-area: CT;

  background-color: #e5e5e5 !important;

  padding: 16px 16px 40px 16px;

  /* cálculo para altura do content */
  /* define que terá scrollbar apenas para a área do content */
  height: calc(100vh - 100px);
  overflow-y: auto;

  /* personalização da scrollbar */
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8a8a8a;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #b1b1b1;
  }

  /**media queries para calcular altura correta do content, conforme a altura do RDM aumenta */
  @media (max-width: 1438px) {
    height: calc(100vh - 123px);
  }

  @media (max-width: 1097px) {
    height: calc(100vh - 147px);
  }

  @media (max-width: 866px) {
    height: calc(100vh - 171px);
  }

  @media (max-width: 832px) {
    height: calc(100vh - 200px);
  }
`;

export const InsideContent = styled.div``;
